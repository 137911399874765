import _ from 'lodash'
import { xhrResponseURL } from '../utils'
import { handleFilterToggle } from './toggle'

export default class AnalysisFilters {
  constructor (props) {
    this.formEl = props.formEl // jquery selector
    this.resultsEl = props.resultsEl  // jquery selector
    this.facetSetIds = Array.isArray(props.facetSetIds) ? props.facetSetIds : []
    this.enableOneAtATimeConf = props.enableOneAtATimeConf // list of pairs of jquery selectors
    this.conditionals = props.conditionals || [
      {
        "input": "part_of_vehicle",
        "value": "No",
        "related": [
          "vehicle",
          "vehicle_type",
          "fund_style",
          "fund_structure"
        ],
        "type": "radio"
      }
      // // ADG: This stopped working at some point in the past, but not sure in 2023-08-21 what it should do.
      // {
      //   "input": "vehicle_type",
      //   "value": [
      //     "Fund"
      //   ],
      //   "related": [
      //     "fund_style",
      //     "fund_structure"
      //   ],
      //   "type": "select"
      // }
    ]
    // delay time to debounce form field changes
    // mostly because of the sliders
    this.debounceTime = props.debounceTime || 250
    console.debug(this)
    this.init()
  }

  /**
   * init
   *
   */
  init () {
    this.expandCollapseComparisonFilters(this.inComparisonMode())
    this.hideSaveButtons()
    this.registerEvents()
    this.initializeConditionalState()
  }

  tagRequestSequence (xhr) {
    if (typeof(self.ajaxRequestSequence) == 'undefined') {
      self.ajaxRequestSequence = 0;
    }
    if (typeof(self.lastXhr) != 'undefined') {
      console.debug('tagRequestSequenceCancel', { requestSequence: self.ajaxRequestSequence})
      self.lastXhr.abort()
    }
    self.lastXhr = xhr;
    self.ajaxRequestSequence = self.ajaxRequestSequence + 1;
    xhr.requestSequence = self.ajaxRequestSequence;
  }

  requestSequenceCorrect (xhr) {
    if (xhr.requestSequence == self.ajaxRequestSequence) {
      console.debug('requestSequenceCorrect', { requestSequence: xhr.requestSequence})
      return true
    } else {
      console.debug('requestSequenceIncorrect', { requestSequence: xhr.requestSequence, outOfOrder: true})
      return false
    }
  }

  hideSaveButtons() {
    $(this.formEl).find('.j-show-filter-set-form').css('visibility', 'hidden')
  }

  enableOneAtATime() {
    if (!this.enableOneAtATimeConf) { return }

    this.enableOneAtATimeConf.forEach(row => {
      var field1 = App.MultiSelect.instances[$(row[0]).attr('id')]
      var field2 = App.MultiSelect.instances[$(row[1]).attr('id')]
      if (!field1 || !field2) { return }

      [
        [field1, field2],
        [field2, field1],
      ].forEach(fields => {
        if (fields[0].getSelections().length > 0) { fields[1].disable() }
        else { fields[1].enable() }
      });
    });
  }

  /**
   * registerEvents
   *
   */
  registerEvents () {
    const self = this
    const ajaxSelector = `${this.formEl}, ${this.resultsEl}`;
    const $form = $(this.formEl)
    const $results = $(this.resultsEl)

    $('.j-expand-filters').on('click', (event) => {
      event.preventDefault()
      const expandFilters = event.target.dataset.state == 'on'
      $('#set').val(expandFilters ? 'compare' : 'a')
      self.expandCollapseComparisonFilters(expandFilters)
      self.submitFilters()
    })

    $(document).on('click', '.j-filter-toggle', handleFilterToggle)


    const debouncedSubmitFilters = _.debounce(self.submitFilters.bind(self), self.debounceTime)

    $form.on('change', (event) => {
      const facet_set = $(event.target).closest('[data-facet_set]').data('facet_set')
      const $save_btn = $('#j-show-filter-set-form-'+facet_set)
      const $save_form = $('#j-filter-set-form-'+facet_set)
      if ($(event.target).closest('.b-filters__facet-set').length > 0) {
        self.onlyFacetsChanged = true
        if (!$save_form.is(':visible')) {
          $save_btn.css('visibility', 'visible')
        }
      }
      debouncedSubmitFilters()
      self.enableOneAtATime()
    })

    $form.on('click', '.j-filter-header', (event) => {
      $(this).toggleClass('closed').nextUntil('.j-filter-header').toggleClass('hidden')
    })

    $form.on('click', '.j-save-filter-set', (event) => {
      self.submitFilters()
    })

    $form.on('click', '.j-copy-filter', (event) => {
      $('<input>').attr({
        type: 'hidden',
        id: 'copy-filter-to',
        name: 'copy-filter-to'
      }).val(event.target.dataset.value).appendTo($form);
      self.submitFilters()
    })

    $results.on('click', '.j-filter-tabs a', (event) => {
      const src_tab = event.target.dataset['tab']
      if (src_tab) {
        $form.find('[name=tab]').val(src_tab)
        self.submitFilters()
        event.preventDefault()
      }
    })

    $results.on('click', '.j-years-selector a', (event) => {
      const years = event.target.dataset['years']
      if (years) {
        $form.find('[name=display_years]').val(years)
        self.submitFilters()
        event.preventDefault()
      }
    })

    $results.on('click', '.c-frequency-selector a', (event) => {
      const frequency = event.target.dataset['frequency']
      if (frequency) {
        $form.find('[name=rf]').val(frequency)
        self.submitFilters()
        event.preventDefault()
      }
    })

    $('#j-filters-clear').on('click', (event) => {
      const href = event.target.getAttribute('href').replace(/\?.*$/, '');
      const clearParams = new URLSearchParams;
      const tab = $form.find('[name=tab]').val();
      const currency = $form.find('[name=currency]').val();
      const rf = $form.find('[name=rf]').val();
      const set = $form.find('[name=set]').val();
      const layout = $form.find('[name=layout]').val();

      if (tab) {
        clearParams.set('tab', tab)
      }
      if (currency) {
        clearParams.set('currency', currency)
      }
      if (rf) {
        clearParams.set('rf', rf)
      }
      if (set) {
        clearParams.set('set', set)
      }
      if (layout) {
        clearParams.set('layout', layout)
      }

      $('#j-filters-clear').attr('href', href + '?' + clearParams.toString())
      return true;
    })

    $(document).on('ajax:send', ajaxSelector, (event) => {
      const xhr = event.detail[0];
      self.tagRequestSequence(xhr);
      console.debug('Analysis#ajax:send', xhr)
    })

    $(document).on('ajax:success', ajaxSelector, (event) => {
      const detail = event.detail;
      const responseText = detail[0], status = detail[1], xhr = detail[2];
      if (!self.requestSequenceCorrect(xhr)) {
        return;
      }
      const resultsHtml = $(responseText).find(self.resultsEl).html()
      const formHtml = $(responseText).find(self.formEl).html()
      console.debug('Analysis#ajax:success', xhr)
      const src_sort_by = $(event.target).val()
      if (src_sort_by) {
        $(self.formEl).find('[name=sort_by]').val(src_sort_by)
      }
      $results.html(resultsHtml)
      if (!self.onlyFacetsChanged) {
        $form.html(formHtml)
        self.hideSaveButtons()
      } else {
        self.onlyFacetsChanged = false
      }
      const responseURL = xhrResponseURL(xhr)
      if (responseURL) {
        window.history.replaceState(null, '', responseURL);
      }
      self.enableOneAtATime()
      self.registerEventsForConditionals()
      self.initializeConditionalState()
    })

    $(document).on('ajax:complete', ajaxSelector, (event) => {
      const xhr = event.detail[0];

      console.debug('Analysis#ajax:complete', xhr)
    })

    self.registerEventsForConditionals()
  }

  /**
   * submitFilters
   * Use Rails UJS fire method to trigger form submit
   */
  submitFilters(event = null) {
    if (window.gtag) {
      window.gtag('event', 'filter');
    }
    const formEl = $(this.formEl)[0];
    console.debug('Analysis#submit', this.formEl, $(formEl).serialize())
    Rails.fire(formEl, 'submit')
  }

  /**
   * filterSetCssSelector
   * @param {String} filterSetId
   *                 Analysis: Either 'a' or b'.
   *                 Non-Analysis: The filter CSS ID (without the #)
   */
  filterSetCssSelector(filterSetId) {
    if (['a', 'b'].includes(filterSetId)) {
      return `${this.formEl} [data-facet_set=${filterSetId}]`
    } else {
      // Example: #search-filters'
      return `${this.formEl} #${filterSetId}`
    }
  }

  /**
   * registerEventsForConditionals
   * Conditional inputs are shown/hidden based on selected values from other inputs.
   * - Ex: "Not related to vehicles" is select, then hide the inputs that relate to vehicle data.
   */
  registerEventsForConditionals () {
    const self = this;

    self.facetSetIds.forEach((filterSetId) => {
      self.conditionals.forEach(({ input, value, related }) => {
        const containingEl = self.filterSetCssSelector(filterSetId);
        $(`${containingEl} [data-facet-input=${input}]`).on('change input', function (event) {
          self.showHideConditional(this, containingEl, input, value, related);
        });
      });
    });
  }

  /**
   * initializeConditionalState
   * Iterate through conditionals of each facet set and ensure shown/hidden
   * based on value of input compared to conditional value (CONDITIONAL['value'])
  */
  initializeConditionalState () {
    const self = this;

    self.facetSetIds.forEach((filterSetId) => {
      self.conditionals.forEach(({ input, value, related, type }) => {
        const containingEl = self.filterSetCssSelector(filterSetId);
        const checked = type === 'radio' ? ':checked' : '';
        Array.from($(`${containingEl} [data-facet-input=${input}]${checked}`)).forEach(object => {
          self.showHideConditional(object, containingEl, input, value, related);
        });
      });
    });
  }

  /**
   * showHideConditional - show/hide related inputs based on selection of input.
  */
  showHideConditional (object, containingEl, input, value, related) {
    // If related elements are already hidden, take no action
    if ($(`${containingEl} [class*=${input}]`).hasClass('hide')) return;

    const val = $(object).val();
    const type = typeof (val);
    let stateMethod = 'addClass'; // addClass means hide, removeClass means show
    if (!val || (type === 'string' && val !== value)) {
      // If the input type is not equal to value, then related elements can be shown
      stateMethod = 'removeClass';
    } else if (type === 'object') {
      // If input is a multi-select, check if allowed values are selected
      let anyMatch = false;
      const stringVal = val.toString();

      value.forEach(v => {
        anyMatch = (stringVal === v ? true : anyMatch);
      });
      if (anyMatch) stateMethod = 'removeClass';
    }

    // Hide or show flter block(s)
    const query = related.map(facet => `${containingEl} .b-filters__filter[data-facet=${facet}]`).toString();
    $(query)[stateMethod]('hide');

    // Clear input values of related hidden fields, so inputs aren't filtering.
    if (stateMethod === 'addClass') {
      $(query).find('select, input').each(function () {
        const $this = $(this);
        if (!$this.val()) return;

        if (['radio'].includes(this.type)) {
          const $allItem = $this.parent().siblings().find('input[value=""]');
          $this.parent().removeClass('active');

          if (!$allItem.prop('checked')) {
            // Select the "all" input
            $allItem.prop('checked', 'checked').trigger('change').parent().addClass('active');
          }
        } else {
          // Clear selected / input value.
          $this.val('').trigger('change');

          // Clears the UI list if input is a multi-select.
          $this.parent().siblings().find('.c-multi-select__selected-item').remove();
          // NOTE: [IA-417] This doesn't reset the Preact object, which if you click on the menu,
          // will can still show items selected if the form hasn't been reloaded.

          // Remove active class from sibling elements.
          $this.parent().siblings().removeClass('active');
        }
      });
    }
  }

  inComparisonMode() {
    return $('.j-expand-filters.active').data('state') === 'on'
  }

  /**
   * expandFilters
   *
   * @param  {Boolean} openFilters
                       Expand or collapsed filters
                       Determined based on comparison button is active (on/off)
   *
   */
  expandCollapseComparisonFilters (openFilters=false) {
    // Add remove classes of filter results and filter wrapper container
    const $filterContent = $('.b-report__content')
    const $filterWrapper = $('.j-filters');
    if (openFilters) {
      $filterWrapper.addClass('expanded active').siblings().removeClass('active')
      $filterContent.addClass('with-expanded-aside')
      if (window.gtag) {
        window.gtag('event', 'compare');
      }
    } else {
      $filterWrapper.removeClass('expanded active').siblings().removeClass('active')
      $filterContent.removeClass('with-expanded-aside')
    }
    // Force charts to re-flow into new parent width
    App.Charts.instances.map( chart => chart.resizeToContainer() )
  }
}
